<template>
  <div role="tablist" class="lzui-nav-tabs vue-tabs" :class="$attrs.class">
    <slot></slot>
  </div>
  <slot name="panels" v-bind="{ activateTab }"></slot>
</template>

<script setup>
import each from 'lodash/each'
import find from 'lodash/find'
import { reactive, provide, onMounted, ref } from 'vue'

const emit = defineEmits(['activated-tab'])
const tabs = reactive({})
const isOverflowVisible = ref(false)

const registerTab = (target, tab) => (tabs[target] = tab)
provide('registerWithTabParent', registerTab)

const activateTab = (target) => tabs[target]?.activate()

const tabActivated = (target) => {
  each(tabs, (tab, key) => {
    if (key !== target) tab.deactivate()
  })

  isOverflowVisible.value = false
  emit('activated-tab', target)
}
provide('tabActivated', tabActivated)

const activateInitial = () => {
  let active
  active = find(tabs, (tab) => !!tab.initial)

  if (!active) active = tabs[Object.keys(tabs)[0]]
  each(tabs, (tab) => (tab === active ? tab.activate() : tab.deactivate()))
}
onMounted(activateInitial)
</script>
