<template>
  <button
    role="tab"
    :aria-selected="active"
    type="button"
    class="text-sm"
    :class="{ active: active }"
    @click="activate"
  >
    <slot>{{ label }}</slot>
  </button>
</template>

<script>
import { inject, onMounted, ref } from 'vue'
export default {
  name: 'TabLink',
  props: {
    label: {
      type: String,
      default: '',
    },

    target: {
      type: String,
      required: true,
    },

    initial: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const panel = ref(null)
    const active = ref(false)

    const parentActivation = inject('tabActivated', (_) => {})
    const activate = () => {
      parentActivation(props.target)

      active.value = true
      if (panel.value) panel.value.style.display = 'block'
    }

    const deactivate = () => {
      active.value = false
      if (panel.value) panel.value.style.display = 'none'
    }

    const register = inject('registerWithTabParent', (_, _t) => {})
    register(props.target, { activate, deactivate, initial: props.initial })

    onMounted(() => (panel.value = document.getElementById(props.target)))

    return { active, activate }
  },
}
</script>
