<template>
  <div class="lzui-nav-tabs vue-tabs max-w-full overflow-y-hidden p-1 pb-0" role="tablist">
    <RoutingLink :link="firstLink" :is-active="isActive(firstLink)" />
    <RoutingLink v-for="link in overflowLinks" :key="link.label" :link="link" :is-active="isActive(link)" />
  </div>
</template>

<script setup>
import every from 'lodash/every'
import some from 'lodash/some'
import RoutingLink from './RoutingLink'
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  links: {
    type: Array,
    required: true,
    validator(links) {
      function hasRequiredProps(link) {
        return (link.path || link.name) && link.label
      }

      return every(links, hasRequiredProps)
    },
  },
})

const noMatchingPath = ref(false)
const firstLink = computed(() => props.links[0])
const overflowLinks = computed(() => props.links.slice(1))
const route = useRoute()
watch(
  () => route.name,
  () => {
    noMatchingPath.value = !some(
      props.links,
      (link) => link.path === route.path || (link.name && link.name === route.name),
    )
  },
  { immediate: true },
)

const isActive = (link) => {
  return route.name === link.name || !!(noMatchingPath.value && link.default)
}
</script>
