<template>
  <RouterLink v-slot="{ navigate }" :to="to" custom active-class="active">
    <button
      v-track-click.once="trackingData"
      role="tab"
      class="whitespace-nowrap"
      :class="{ active: isActive }"
      :aria-selected="isActive"
      @click="navigate"
    >
      {{ link.label }}
    </button>
  </RouterLink>
</template>

<script setup>
import { default as vTrackClick } from 'vue_features/shared/directives/track_click'
import { computed } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

const route = useRoute()
const to = computed(() => {
  return { ...props.link, query: route.query }
})

const props = defineProps({
  link: { type: Object, required: true },
  isActive: { type: Boolean, required: true },
})

const trackingData = computed(() => {
  const { eventName, eventProperties } = props.link
  return { eventName, eventProperties }
})
</script>
