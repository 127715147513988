import { reactive } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'

function isIpad() {
  return navigator.userAgent.includes('Mac') && 'ontouchend' in document
}

export const useGlobalStore = defineStore('global', () => {
  const state = reactive({
    locale: null,
    defaultOneTimeReturnToPath: '',
    isContentEmbedded: false,
    isTabletDevice: isIpad(),
    isMobileDevice:
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || isIpad(),
  })

  return { state }
})
